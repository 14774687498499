<template>
  <div class="flex items-center justify-between gap-x-3">
    <custom-button
      v-if="presentation?.suggestion"
      class="px-2 py-2 text-base max-xl:w-[38px] h-[38px] hidden lg:flex items-center justify-center"
      size="none"
      pill
      @click="backToSuggestion"
    >
      <nuxt-icon
        name="common/arrows/arrow-return-right"
        class="text-xs md:text-base"
      />
      <span class="hidden xl:inline ml-2 font-semibold whitespace-nowrap">
        {{ $t('building.presentation.actions.returnToSuggestion.label') }}
      </span>
    </custom-button>

    <div
      v-if="presentation?.organisation"
      class="flex"
    >
      <organisation-view
        :organisation="presentation.organisation"
        avatar-size="xs"
        class="mr-7"
      />
      <manager-view
        :manager="presentation"
        avatar-size="xs"
      />
    </div>
    <div class="flex gap-x-3">
      <chat-with-manager-btn
        class="px-4 h-[38px]"
        span-class="hidden xl:inline"
      />
      <book-viewing-btn
        v-if="presentation?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
        class="px-4 h-[38px]"
        span-class="hidden xl:inline"
        :mobile-phone="presentation?.contactsSettings.messengers?.whatsapp?.phoneNumber"
        :text-to-send="textToSend"
      />
    </div>
    <messengers-btns
      v-if="presentation?.contactsSettings?.messengers?.telegram?.phoneNumber || presentation?.messengers?.whatsapp?.phoneNumber"
      :messengers="presentation.contactsSettings?.messengers"
      button-class="h-[38px] px-4"
      span-class="hidden lg:inline"
    />
    <select-language color="primary" />
  </div>
</template>

<script setup lang="ts">

import OrganisationView from '~/components/organisation/OrganisationView.vue'
import MessengersBtns from '~/components/buttons/MessengersBtns.vue'
import BookViewingBtn from '~/components/buttons/BookViewingBtn.vue'
import { useBuildingStore } from '~/modules/building/store'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ChatWithManagerBtn from '~/components/buttons/ChatWithManagerBtn.vue'
import SelectLanguage from '~/modules/app-language/components/SelectLanguage.vue'

const store = useBuildingStore()
const { t } = useI18n()

const presentation = computed(() => store.presentation)
const textToSend = computed(() => t('building.presentation.bookViewing.textToSend', { building: presentation.value?.building }))

const backToSuggestion = () => {
  if (presentation.value?.suggestion) {
    navigateTo(`/suggestions/${presentation.value.suggestion}`)
  }
}
</script>
